import React, { useState, useRef } from 'react';
import SortableTabs from '../table-headers/SortableTabs';
import { formatPrice } from '../../utils/formatPrice';
import MyobPaymentRow from './MyobPaymentRow';
import InvoicePaymentsFilter from './InvoicePaymentsFilter';
const MyobPaymentsTable = ({ myobPayment, companyPaymentsPath }) => {
    const allCheckboxRef = useRef(null);
    const [sortBy, setSortBy] = useState('id');
    const [sortDirection, setSortDirection] = useState('desc');
    const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);
    const [computedPrice, setComputedPrice] = useState(0);
    const headerFields = [
        {
            text: '',
            type: 'checkbox',
            checkboxRef: allCheckboxRef
        },
        { text: 'ID' },
        { text: 'Date' },
        { text: 'Customer Name' },
        { text: 'Account Name' },
        { text: 'Description' },
        { text: 'Amount' },
        { text: 'Customer' },
        { text: '# of Invoices' },
        { text: '' }
    ];
    const handleAllCheckboxChange = (e) => {
        const allPaymentIds = myobPayment.map(t => t.id);
        const price = myobPayment.reduce((prev, curr) => prev + curr.amountCents, 0);
        if (e.target.checked) {
            setSelectedPaymentIds(allPaymentIds);
            setComputedPrice(price);
        }
        else {
            setSelectedPaymentIds([]);
            setComputedPrice(0);
        }
    };
    const isSelected = paymentId => {
        return selectedPaymentIds.includes(paymentId);
    };
    const toggleAllCheckbox = checked => {
        if (allCheckboxRef && allCheckboxRef.current) {
            allCheckboxRef.current.checked = checked;
        }
    };
    const handlePaymentId = (paymentId) => {
        const newIds = [...selectedPaymentIds];
        if (isSelected(paymentId)) {
            newIds.splice(newIds.indexOf(paymentId), 1);
        }
        else {
            newIds.push(paymentId);
        }
        const newPayments = myobPayment.filter((payment) => {
            return newIds.includes(payment.id);
        });
        const price = newPayments.reduce((prev, curr) => prev + curr.amountCents, 0);
        setSelectedPaymentIds(newIds);
        setComputedPrice(price);
        const isAllSelected = newIds.length === myobPayment.length;
        toggleAllCheckbox(isAllSelected);
    };
    const sortPayments = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    const setClassAmount = amount => {
        return amount > 0 ? 'total' : 'zero';
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'payments-table' },
        React.createElement("div", { className: 'header -space-between' },
            React.createElement("div", null,
                "Total Amount of Selected Payments:",
                ' ',
                React.createElement("span", { className: setClassAmount(computedPrice), "data-testid": 'computed-payments' }, computedPrice > 0 ? formatPrice(computedPrice / 100) : '$0.00')),
            React.createElement("div", { className: 'actions' },
                React.createElement(InvoicePaymentsFilter, { companyPaymentsPath: companyPaymentsPath }))),
        React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTabs, { isLoading: false, headerFields: headerFields, sortItems: sortPayments, checkboxOnChange: handleAllCheckboxChange, sortBy: 'id', sortDirection: 'desc' }),
            React.createElement("tbody", null, myobPayment.length !== 0 ? (myobPayment.map(payment => (React.createElement(MyobPaymentRow, { key: payment.id, payment: payment, handlePaymentId: handlePaymentId, selected: isSelected(payment.id) })))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 12 }, "There are no transactions to display")))))));
};
export default MyobPaymentsTable;
