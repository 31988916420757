import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { buildUrlQuery } from '../../utils/url';
import CustomFlatpickr, { defaultFlatpickrSettings } from '../shared/CustomFlatpickr';
import { formatDateRange } from '../../utils/formatDateRange';
const InvoicePaymentsFilter = ({ companyPaymentsPath }) => {
    const [isOpen, setIsOpen] = useState(false);
    const panelRef = useRef(null);
    const togglePanel = () => setIsOpen(!isOpen);
    const appliedDateRangeFilter = new URLSearchParams(window.location.search).get('date_range') || '';
    const [dateRange, setDateRange] = useState(appliedDateRangeFilter);
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setDateRange(dateString);
    };
    const flatpickrSettings = {
        mode: 'range',
        onChange: handleSelectedDateChange,
        defaultDate: dateRange,
        ...defaultFlatpickrSettings
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const filterUrl = buildUrlQuery({
        date_range: dateRange
    }, companyPaymentsPath);
    const panelClass = classNames('panel', {
        '-active -z-10 -width-320': isOpen
    });
    return (React.createElement("div", { className: 'process-panel -filter-process', "data-testid": 'filter-dropdown' },
        React.createElement("div", { className: 'label' }, !isOpen &&
            (appliedDateRangeFilter !== ''
                ? formatDateRange(appliedDateRangeFilter)
                : 'All Payments')),
        React.createElement("a", { className: 'icon-button button -rectangular -border-brand-a-400 -filter -process-button -icon-filter-brand-a-400 -text-brand-a-400', onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Filter"),
            React.createElement("div", { className: 'icon' })),
        React.createElement("div", { className: panelClass, "data-testid": 'panel', ref: panelRef },
            React.createElement("div", { className: 'dropdowns' },
                React.createElement("div", { className: 'form-group -collapse' },
                    React.createElement("div", { className: 'label -double-spaced' }, "Filter By"),
                    React.createElement("label", { className: 'label -above-spaced -uppercase -small' }, "Date Range"),
                    React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: dateRange, pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' }),
                    React.createElement("label", { className: 'note' }, "Clear date and apply filter to display all payments")),
                React.createElement("div", { className: 'action-buttons' },
                    React.createElement("a", { className: 'button -cancel -border -loose -loose-panel', href: companyPaymentsPath }, "Reset"),
                    React.createElement("a", { className: 'button -primary -loose -loose-panel', href: filterUrl }, "Apply Filter"))))));
};
export default InvoicePaymentsFilter;
